@import '../../../colors.scss';

.portal-sidemenu {
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: $darkGray;
    margin: 10px;
    margin-right: 0px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px $black;
}