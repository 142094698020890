@import '../../colors.scss';
@import "~@fontsource/paytone-one/index.css";

.applications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;

    .application {
        background-color: $gold;
        box-shadow: 1px 1px 4px $black;
        border-radius: 5px;
        width: 100%;
        max-width: 1000px;
        padding: 10px;
        margin-bottom: 20px;

        .application-header {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .application-header-title {
                font-family: 'Paytone One', sans-serif;
                font-size: 40px;
                color: $darkGray;
                font-weight: bold;
                text-transform: uppercase;
            }

            .application-header-status {
                display: flex;
                font-size: 25px;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                font-weight: bold;
                margin-right: 10px;
                height: 30px;
                border-radius: 5px;
                padding: 5px 20px;
                box-shadow: 1px 1px 4px $black;

                &.open {
                    background-color: green;
                }

                &.closed {
                    background-color: red;
                    color: $white;
                }

                &.denied {
                    background-color: red;
                    color: $white;
                }

                &.accepted {
                    background-color: green;
                }

                &.review {
                    background-color: rgb(47, 139, 201);
                    color: $white;
                }

                &.coming-soon {
                    background-color: orange;
                    color: $black;
                }

                &.pending {
                    background-color: purple;
                    color: $white;
                }

                &.grandfathered {
                    background-color: rgb(228, 65, 174);
                    color: $white;
                }
            }
        }

        .application-details {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 18px;
        }

        .application-response {
            background-color: $mediumgray;
            color: $white;
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 18px;
            margin-bottom: 10px;
            box-shadow: 1px 1px 4px $black;
        }

        .application-footer {
            margin-top: 20px;
            margin-left: 10px;
            margin-bottom: 10px;

            .application-footer-button {
                background-color: $black;
                color: $gold;
                padding: 10px 20px;
                font-weight: bold;
                font-size: 20px;
                border-radius: 10px;
                margin-top: 10px;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }
}