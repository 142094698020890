@import '../../colors.scss';

.whitelist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    height: calc(100% - 50px);

    .whitelist-header {
        font-family: 'Paytone One', sans-serif;
        font-size: 60px;
        text-transform: uppercase;
        color: $gold;
        font-weight: bold;
        margin-bottom: 20px;
        text-shadow: 1px 1px 4px $black;
    }

    .whitelist-container {
        &::-webkit-scrollbar { width: 0px; }

        color: $white;
        overflow-y: auto;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $black;
        width: 100%;
        max-width: 1000px;
        padding: 20px;
        background: rgba(0, 0, 0, 0.6);

        .whitelist-submit-button {
            background-color: $gold;
            color: $black;
            padding: 10px 20px;
            font-weight: bold;
            font-size: 20px;
            border-radius: 10px;
            margin-top: 10px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}

.checkbox-input {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    .checkbox-value-container {
        display: flex;
        margin-left: 5px;
        font-size: 20px;

        .checkbox-input-error {
            margin-left: 5px;
            font-weight: bold;
            text-transform: uppercase;
            color: $error;
        }
    }
}

.textarea-input {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    .textarea-input-header {
        display: flex;
        font-size: 20px;
    }

    .textarea-input-error {
        margin-top: 5px;
        font-weight: bold;
        text-transform: uppercase;
        color: $error;
    }

    .textarea-input-value {
        margin-top: 10px;
        min-height: 150px;
        width: calc(100% - 10px);
        font-size: 16px;
        background-color: #525252;
        color: white;
        resize: none;
        padding: 5px;
        border: none;
        overflow: auto;
        box-shadow: 1px 1px 5px $black;
        outline: none;
        border-radius: 5px;
    }
}

.text-input {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;

    .text-input-header {
        display: flex;
        font-size: 20px;
    }

    .text-input-error {
        margin-top: 5px;
        font-weight: bold;
        text-transform: uppercase;
        color: $error;
    }

    .text-input-value {
        margin-top: 10px;
        font-size: 16px;
        background-color: #525252;
        width: 300px;
        color: white;
        padding: 5px;
        border: none;
        overflow: auto;
        box-shadow: 1px 1px 5px $black;
        outline: none;
        border-radius: 5px;
    }
}