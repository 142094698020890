@import '../../colors.scss';

.legendary-selection-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    .legendary-selection-input-title {
        color: $white;
        font-size: 12px;
        margin-bottom: 2px;
    }

    .legendary-selection-input-container {
        width: 100%;
        font-size: 14px;
        align-items: center;

        &.higher {
            z-index: 100;
        }
    }

    .legendary-selection-input-error {
        font-size: 12px;
        margin-top: 4px;
        color: $error;
    }
}