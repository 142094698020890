@import '../../../../colors.scss';

.whitelist-apps-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whitelist-apps {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .whitelist-apps-filtering {

    }

    .whitelist-apps-table {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;

        &::-webkit-scrollbar { width: 0px; }

        .whitelist-apps-table-header {
            display: flex;
            background-color: $black;
            color: $white;
            text-align: center;
            font-size: 20px;
            padding-top: 10px;
            padding-bottom: 10px;

            .whitelist-apps-header-actions {
                width: 10%;
            }

            .whitelist-apps-header-name {
                width: 20%;
            }

            .whitelist-apps-header-status {
                width: 10%;
            }

            .whitelist-apps-header-date {
                width: 25%;
            }

            .whitelist-apps-header-last-date {
                width: 25%;
            }

            .whitelist-apps-header-last-person {
                width: 20%;
            }
        }

        .whitelist-apps-table-content {
            .whitelist-apps-table-row {
                display: flex;
                color: $white;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                align-items: center;

                &:nth-child(even) {
                    background-color: $mediumgray;
                }

                .whitelist-apps-row-actions {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .whitelist-apps-row-claim {
                        background-color: $gold;
                        color: $black;
                        padding: 5px 10px;
                        font-weight: bold;
                        border-radius: 5px;
                        cursor: pointer;
                        box-shadow: 1px 1px 4px $black;
                    }
                }

                .whitelist-apps-row-name {
                    width: 20%;
                }
    
                .whitelist-apps-row-status {
                    width: 10%;

                    .whitelist-apps-row-status-text {
                        color: $black;
                        padding: 5px 10px;
                        font-weight: bold;
                        border-radius: 5px;
                        cursor: pointer;
                        box-shadow: 1px 1px 4px $black;

                        &.approved {
                            background-color: green;
                            color: $white;
                        }
            
                        &.denied {
                            background-color: red;
                            color: $white;
                        }
            
                        &.review {
                            background-color: rgb(47, 139, 201);
                            color: $white;
                        }
            
                        &.coming-soon {
                            background-color: orange;
                            color: $black;
                        }
            
                        &.pending {
                            background-color: purple;
                            color: $white;
                        }
                    }
                }
    
                .whitelist-apps-row-date {
                    width: 25%;
                }
    
                .whitelist-apps-row-last-date {
                    width: 25%;
                }
    
                .whitelist-apps-row-last-person {
                    width: 20%;
                }
            }
        }
    }
}