@import '../../../colors.scss';

.portal-sidemenu-item {
    display: flex;
    padding-left: 10px;
    align-items: center;
    color: white;
    height: 40px;
    cursor: pointer;

    &.expanded-child {
        background-color: #505050;
    }

    &.selected {
        &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        background-color: $mediumgray;
    }

    &:hover {
        &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        background-color: $mediumgray;
    }

    .portal-sidemenu-item-icon {
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 5px;
    }

    .portal-sidemenu-expand-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;
     }

    .portal-sidemenu-item-name {
        user-select: none;
        font-size: 14px;
        font-weight: bold;
        margin-left: 10px;
    }
}