@import '../../colors.scss';

.header {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 90px;

    .left-header {
        margin-left: 20px;
        display: flex;

        .header-item {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: $white;
            font-weight: bold;
            font-size: 24px;
            border-radius: 5px;
            margin-left: 10px;
            margin-right: 10px;
            padding: 10px 30px;

            &:hover {
                color: $mediumgray;
            }

            &.selected {
                color: $gold;
            }
        }
    }

    .logo-header {
        height: 100%;
        display: flex;
        margin-left: 20px;
        align-items: center;
        justify-content: center;
    }

    .login-section {
        margin-left: auto;
        margin-right: 20px;

        .login-section-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            font-size: 24px;
            color: $black;
            background-color: $gold;
            border-radius: 5px;
            height: 40px;
            font-weight: bold;
        }

        .login-details {
            display: flex;

            .user-details {
                color: $white;
                font-size: 18px;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: center;

                .user-logout {
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            
            .user-avatar {
                border-radius: 50%;
                height: 60px;
                width: 60px;
                box-shadow: 1px 1px 4px $black;
            }
        }
    }
}