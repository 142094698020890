@import '../../colors.scss';

.home {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $white;

    .home-coming-soon {
        font-size: 70px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .home-description {
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 800px;
    }

    .home-discord-button {
        background-color: $black;
        color: $gold;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 30px;
        border-radius: 10px;
        margin-top: 20px;
        text-transform: uppercase;
        cursor: pointer;
    }
}