@import '../../colors.scss';

.developer {
    display: flex;
    margin: 20px;
    height: calc(100% - 40px);

    .recipe-generator {
        display: flex;
        width: 100%;

        .basic-info {
            width: 25%;

            .basic-info-header {
                color: $white;
                text-align: center;
            }

            .basic-info-container {

            }
        }

        .item-recipes {
            margin-left: 20px;
            width: 40%;
            display: flex;
            flex-direction: column;

            .item-recipes-header {
                color: $white;
                text-align: center;
            }

            .item-recipes-container {
                .item-recipe-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .item-recipe-item-selection {
                        min-width: 70%;
                        margin-right: 10px;
                    }
    
                    .item-recipe-item-quantity {
                        width: 100%;
                    }

                    .item-recipe-item-remove {
                        cursor: pointer;
                        color: $white;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                }
            }

            .item-recipes-add-item {
                background-color: $black;
                color: $gold;
                text-align: center;
                padding: 10px 20px;
                font-weight: bold;
                font-size: 20px;
                border-radius: 10px;
                margin-top: 10px;
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .item-code {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            width: 35%;

            .item-code-container {
                height: 100%;

                .item-code-header {
                    color: $white;
                    text-align: center;
                }
    
                .item-code-display {
                    width: 100%;
                    height: calc(100% - 70px);
    
                    font-size: 13px;
                    background-color: #525252;
                    color: white;
                    resize: none;
                    padding: 5px;
                    border: none;
                    overflow: auto;
                    box-shadow: 1px 1px 5px $black;
                    outline: none;
                    border-radius: 5px;
                }
            } 
        }
    }
}