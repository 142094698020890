@import './colors.scss';
@import "~@fontsource/montserrat/index.css";

html {
    height: 100%;
}
  
body {
    height: 100%;
    margin: 0;
    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-container {
  height: 100%;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-container {
  flex: 1;
  height: calc(100% - 90px);
  background-image: url('./images/Pink_Background.jpg');

  .staff-container {
    display: flex;
    height: 100%;
    color: $white;

    .staff-content {
        width: calc(100% - 250px);
        background-color: $darkGray;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $black;

        .portal-content {
            display: flex;
            height: 100%;
        }
    }
}
}