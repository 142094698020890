@import '../../../../colors.scss';

.items-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.items-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .items-filtering {
        margin: 10px;
        .items-filtering-search {
            width: 400px;
        }
    }

    .items-table {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;

        &::-webkit-scrollbar { width: 0px; }

        .items-table-header {
            display: flex;
            background-color: $black;
            color: $white;
            text-align: center;
            font-size: 20px;
            padding-top: 10px;
            padding-bottom: 10px;

            .items-table-header-id {
                width: 10%;
            }

            .items-table-header-item {
                width: 25%;
            }

            .items-table-header-label {
                width: 25%;
            }

            .items-table-header-limit {
                width: 10%;
            }

            .items-table-header-weight {
                width: 10%;
            }

            .items-table-header-group {
                width: 20%;
            }
        }

        .items-table-content {
            .items-table-row {
                display: flex;
                color: $white;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;

                &:nth-child(even) {
                    background-color: $mediumgray;
                }

                .items-table-row-id {
                    width: 10%;
                }
    
                .items-table-row-item {
                    width: 25%;
                }
    
                .items-table-row-label {
                    width: 25%;
                }
    
                .items-table-row-limit {
                    width: 10%;
                }
                
                .items-table-row-weight {
                    width: 10%;
                }

                .items-table-row-group {
                    width: 20%;
                }
            }
        }
    }
}