@import '../../../../colors.scss';

.whitelist-apps-view-loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whitelist-apps-view {
    height: calc(100% - 125px);

    .whitelist-apps-view-header {
        display: flex;
        align-items: center;
        padding: 10px 10px 0 10px;

        .whitelist-apps-view-header-title {
            font-size: 40px;
            font-weight: bold;
            text-transform: uppercase;
        }

        .whitelist-apps-view-header-status {
            display: flex;
            font-size: 25px;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            font-weight: bold;
            height: 30px;
            border-radius: 5px;
            padding: 5px 20px;
            box-shadow: 1px 1px 4px $black;

            &.approved {
                background-color: green;
                color: $white;
            }

            &.denied {
                background-color: red;
                color: $white;
            }

            &.review {
                background-color: rgb(47, 139, 201);
                color: $white;
            }

            &.pending {
                background-color: purple;
                color: $white;
            }
        }
    }

    .whitelist-apps-view-admin {
        padding: 10px;

        .whitelist-apps-view-admin-claim {
            background-color: $gold;
            width: 75px;
            color: $black;
            padding: 5px 10px;
            font-weight: bold;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            box-shadow: 1px 1px 4px $black;
        }

        .whitelist-apps-view-admin-claimdetails {
            display: flex;
            flex-direction: column;

            .whitelist-apps-view-admin-claimdetails-buttons {
                display: flex;

                .whitelist-apps-view-admin-claimdetails-button {
                    width: 75px;
                    color: $black;
                    padding: 5px 10px;
                    font-weight: bold;
                    border-radius: 5px;
                    cursor: pointer;
                    text-align: center;
                    box-shadow: 1px 1px 4px $black;
                    margin-right: 10px;
    
                    &.approve {
                        background-color: $success;
                    }
    
                    &.deny {
                        background-color: $error;
                    }
    
                    &.unclaim {
                        background-color: $gold;
                    }
                }
            }

            .whitelist-apps-view-admin-claimdetails-response {
                .whitelist-apps-view-admin-claimdetails-response-input {
                    margin-top: 10px;
                    min-height: 100px;
                    width: calc(100% - 10px);
                    font-size: 16px;
                    background-color: #525252;
                    color: white;
                    resize: none;
                    padding: 5px 5px 0 5px;
                    border: none;
                    overflow: auto;
                    box-shadow: 1px 1px 5px $black;
                    outline: none;
                    border-radius: 5px;
                }
            }
        }
    }

    .whitelist-apps-view-content {
        width: calc(100% - 20px);
        height: 100%;
        overflow-y: auto;
        border-radius: 5px;
        padding: 0 10px 10px 10px;

        &::-webkit-scrollbar { width: 0px; }

        .whitelist-apps-view-content-row {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            background-color: $mediumgray;
            border-radius: 5px;
            padding: 10px;
            box-shadow: 1px 1px 4px $black;

            .whitelist-apps-view-content-row-container {
                display: flex;

                .whitelist-apps-view-content-row-error {
                    margin-left: 10px;
                    font-weight: bold;
                    color: $error;
                }
            }

            .whitelist-apps-view-content-row-label {
                font-weight: bold;
                margin-bottom: 5px;

                &.underage {
                    color: red;
                }
            }

            .whitelist-apps-view-content-row-value {

            }
        }
    }
}