@import '../../colors.scss';

.legendary-text-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    .legendary-text-input-title {
        color: $white;
        font-size: 12px;
        margin-bottom: 2px;
    }

    .legendary-text-input-clickable {
        color: $white;
        cursor: pointer;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 2px;
        text-decoration: underline;
    }

    .legendary-text-input-container {
        width: 100%;
        display: flex;
        align-items: center;

        svg {
            z-index: 100;
        }
    
        .legendary-text-input-value {
            border: none;
            color: $white;
            background-color: $mediumgray;
            box-shadow: 1px 1px 3px $black;
            font-family: "Roboto";
            height: 35px;
            width: 100%;
            padding-left: 10px;
            border-radius: 5px;
            margin-top: 5px;

            &.clickable {
                cursor: pointer;
            }

            &::placeholder {
                color: $white;
            }
    
            &:focus {
                outline: none;
            }

            &:read-only {
                cursor: pointer;
            }
        }
    }

    .legendary-text-input-error {
        font-size: 12px;
        margin-top: 4px;
        color: $error;
    }
}