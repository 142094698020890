.loading-indicator {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .loading-label {
        color: white;
        margin-top: 10px;
    }
}