@import '../../colors.scss';

.portal-header {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 50px;

    .portal-header-left {
        margin-left: 10px;
        display: flex;

        .portal-header-back {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 5px;
            background-color: $gold;
            box-shadow: 1px 1px 4px $black;

            .portal-header-back-text {
                margin-left: 2px;
                font-weight: bold;
            }
        }
    }

    .portal-header-center {
        display: flex;

        .portal-header-role {
            font-weight: bold;
            margin-left: 15px;
            padding: 5px;
            border-radius: 5px;
            box-shadow: 1px 1px 4px $black;

            &.role-developer {
                background-color: #42f5c8;
            }

            &.role-management {
                background-color: #ad3131;
                color: $white;
            }

            &.role-staff {
                background-color: #50b5c7;
            }
        }
    }

    .portal-header-right {
        display: flex;
        margin-left: auto;
        margin-right: 10px;
        justify-content: center;
        align-items: center;

        .portal-header-user {
            display: flex;
            justify-content: center;
            align-items: center;

            .portal-header-avatar {
                border-radius: 50%;
                height: 40px;
                width: 40px;
                box-shadow: 1px 1px 4px $black;
            }

            .portal-header-username {
                margin-left: 10px;
                font-weight: 18px;
                color: $white;
                font-weight: bold;
            }
        }
    }
}