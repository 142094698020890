@import '../../colors.scss';

.search-bar {
    background-color: $mediumgray;
    box-shadow: 1px 1px 3px $black;
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;

    .search-bar-value {
        background: transparent;
        border: none;
        height: 20px;
        font-family: "Montserrat";
        caret-color: $white;
        color: $white;
        padding-left: 20px;
        width: 100%;
        margin-left: -12px;
        margin-top: -2px;
        margin-right: -15px;
    
        &.with-icons {
            margin-right: -15px;
        }

        &::placeholder {
            color: white;
        }

        &:focus {
            outline: none;
        }
    }
}